import React from "react"
import ActivityCard from "../../components/ActivityCard"

export default function Table({
  activities,
  onActivityHover,
  onActivityLeave,
  currentPage,
  totalPages,
  onPageChange,
  totalActivities,
  itemsPerPage,
  city,
  isLoading, // Neuer Prop für den Ladezustand
}) {
  const renderPaginationButtons = () => {
    const buttons = []
    const maxVisiblePages = 3

    // Add left arrow
    buttons.push(
      <button
        key="prev"
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
        disabled={currentPage === 1}
      >
        &larr;
      </button>
    )

    // Add first page
    buttons.push(
      <button
        key={1}
        onClick={() => onPageChange(1)}
        className={`mx-1 px-3 py-1 rounded ${
          currentPage === 1
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-700"
        }`}
      >
        1
      </button>
    )

    // Add ellipsis if needed
    if (currentPage > maxVisiblePages) {
      buttons.push(<span key="ellipsis1">...</span>)
    }

    // Add middle pages
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      buttons.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === i
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          {i}
        </button>
      )
    }

    // Add ellipsis if needed
    if (currentPage < totalPages - maxVisiblePages + 1) {
      buttons.push(<span key="ellipsis2">...</span>)
    }

    // Add last page if not already included
    if (totalPages > 1) {
      buttons.push(
        <button
          key={totalPages}
          onClick={() => onPageChange(totalPages)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === totalPages
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          {totalPages}
        </button>
      )
    }

    // Add right arrow
    buttons.push(
      <button
        key="next"
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
        disabled={currentPage === totalPages}
      >
        &rarr;
      </button>
    )

    return buttons
  }

  const formatActivityCount = (count) => {
    if (count >= 1000) {
      return `Over ${Math.floor(count / 1000)},000`
    }
    return count
  }

  const activityCountText =
    totalActivities > 0
      ? `${formatActivityCount(totalActivities)} ${
          totalActivities === 1 ? "activity" : "activities"
        }${city ? ` in ${city}` : ""}`
      : "No activities found"

  const startIndex = (currentPage - 1) * itemsPerPage + 1
  const endIndex = Math.min(currentPage * itemsPerPage, totalActivities)

  return (
    <div className="w-full flex flex-col h-full items-center p-6 flex-grow">
      <div className="w-full flex flex-col">
        <span className="text-sm font-semibold mb-4">{activityCountText}</span>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : activities && activities.length > 0 ? (
          <>
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-6">
              {activities.map((activity, index) => (
                <ActivityCard
                  key={index}
                  activity={activity}
                  onHover={onActivityHover}
                  onLeave={onActivityLeave}
                />
              ))}
            </div>
            <div className="my-6 flex justify-center items-center">
              {renderPaginationButtons()}
            </div>
          </>
        ) : (
          <div className="text-center py-4">No activities found.</div>
        )}
      </div>
    </div>
  )
}
