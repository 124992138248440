import React, { useState, useEffect } from "react"
import { auth, GoogleAuthProvider, firestore } from "../firebase"

import { telegram } from "../utils/telegram"

const AuthContext = React.createContext()

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [activities, setActivities] = useState(null)

  async function configUser(user) {
    // const configRef = firestore.collection("config").doc("default")
    // const configDoc = await configRef.get()

    // let free_balance = 8

    // if (configDoc.exists) {
    //   const configData = configDoc.data()
    //   free_balance =
    //     configData.freeBalance !== undefined
    //       ? configData.freeBalance
    //       : free_balance
    // }

    const userRef = firestore.collection("users").doc(user.uid)

    const createdAt = new Date()

    const referrer = sessionStorage.getItem("referrer")

    await userRef.set({
      email: user.email,
      balance: 0,
      createdAt: createdAt,
      referrer: referrer,
      plan: "free",
    })

    //telegram("New user: " + user.email + ", got balance: " + free_balance)
    //spammt mich ständig zu mit new user
  }

  async function signupWithEmail(email, password) {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      )
      const user = userCredential.user
      await configUser(user)
      return "success"
    } catch (error) {
      telegram(
        "frontend, AuthContext, signupWithEmail, error: " + error.message
      )
      throw new Error("Failed to create an account")
    }
  }

  async function signupWithGoogle() {
    try {
      const provider = new GoogleAuthProvider()
      const userCredential = await auth.signInWithPopup(provider)
      const user = userCredential.user
      const isNewUser = userCredential.additionalUserInfo?.isNewUser

      if (isNewUser) {
        await configUser(user)
        return "new user"
      } else {
        return "existing user"
      }
    } catch (error) {
      // telegram(
      //   "frontend, AuthContext, signupWithGoogle, error: " + error.message
      // )
      // spammt mich ständig zu mit pop up error
    }
  }

  function login(email, password) {
    try {
      telegram("User login: " + email)
      return auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      telegram("frontend, AuthContext, login, error: " + error.message)
    }
  }

  const logout = async () => {
    await auth.signOut()
    setCurrentUser(null)
    setUser(null)
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  // Remove real-time listeners for user data, activities, and posts
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDoc = await firestore
            .collection("users")
            .doc(currentUser.uid)
            .get()
          if (userDoc.exists) {
            const userData = userDoc.data()
            const paymentsSnapshot = await firestore
              .collection("users")
              .doc(currentUser.uid)
              .collection("payments")
              .get()
            const paymentsData = paymentsSnapshot.docs.map((doc) => doc.data())
            const sumOfCredits = paymentsData
              .filter((item) => item.status === "succeeded")
              .flatMap((item) => item.items)
              .map((item) => parseInt(item.price.metadata.credits))
              .filter((credits) => !isNaN(credits))
              .reduce((acc, curr) => acc + curr, 0)

            setUser({
              id: currentUser.uid,
              ...userData,
              credits_bought: sumOfCredits,
            })
          }
        } catch (error) {
          telegram(
            "frontend, AuthContext, fetchUserData, error: " + error.message
          )
        }
      }
    }

    fetchUserData()
  }, [currentUser])

  // Remove the useEffect for activities and posts

  const value = {
    currentUser,
    signupWithGoogle,
    signupWithEmail,
    login,
    logout,
    resetPassword,
    user,
    // Remove activities and posts from the context value
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
