import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import Map, { Marker, NavigationControl } from "react-map-gl"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import MapboxWorker from "./mapboxWorker"
import categories from "../../assets/categories"

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoicGhpbHoxMjMiLCJhIjoiY20wY2dicWVxMDI4ZjJscXMxd290NzEwYyJ9.S1bzipbWvz-WgWAUK47bzw"

const FALLBACK_IMAGE =
  "https://via.placeholder.com/300x200?text=No+Image+Available"

mapboxgl.workerClass = MapboxWorker

// Funktion zur Bestimmung der Hintergrundfarbe basierend auf dem Rating
const getRatingBackgroundColor = (rating) => {
  if (!rating) return "bg-gray-500"
  if (rating >= 4.5) return "bg-green-500"
  if (rating >= 4) return "bg-lime-500"
  if (rating >= 3.5) return "bg-yellow-500"
  if (rating >= 3) return "bg-orange-500"
  return "bg-red-500"
}

export default function MapView({
  activities,
  filters,
  center,
  zoom,
  hoveredActivity,
  onMapMove,
  forceUpdate,
}) {
  const history = useHistory()
  const [viewState, setViewState] = useState({
    longitude: center.lng,
    latitude: center.lat,
    zoom: zoom,
  })

  const [popupInfo, setPopupInfo] = useState(null)
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 })
  const mapRef = useRef(null)
  const popupRef = useRef(null)

  useEffect(() => {
    setViewState({
      longitude: center.lng,
      latitude: center.lat,
      zoom: zoom,
    })
  }, [center, zoom])

  const handleMouseEnter = (activity, event) => {
    const map = mapRef.current.getMap()
    const point = map.project([activity.location.lng, activity.location.lat])

    setPopupInfo(activity)
    setPopupPosition({ x: point.x, y: point.y })
  }

  const renderMarker = (activity, isHovered) => {
    const categoryEmoji =
      categories.find(
        (cat) =>
          cat.name.toLowerCase() === (activity.category || "").toLowerCase()
      )?.emoji || "❓"

    return (
      <Marker
        key={activity.id}
        longitude={activity.location.lng}
        latitude={activity.location.lat}
        anchor="center"
      >
        <div
          onMouseEnter={(e) => handleMouseEnter(activity, e)}
          onMouseLeave={() => setPopupInfo(null)}
          onClick={() => history.push(`/activity/${activity.unique_name}`)}
          className={`cursor-pointer rounded-xl w-16 h-8 flex items-center justify-center text-white text-lg font-bold transition duration-300 transform ${
            isHovered ? "scale-125" : "scale-100"
          } ${getRatingBackgroundColor(activity.rating)}`}
        >
          {activity.rating ? (
            <>
              <span className="mr-1">{categoryEmoji}</span>
              {activity.rating.toFixed(1)}
            </>
          ) : (
            <span>{categoryEmoji}</span>
          )}
        </div>
      </Marker>
    )
  }

  const handleMapMove = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap()
      const bounds = map.getBounds()
      onMapMove({ bounds: bounds })
    }
  }

  useEffect(() => {
    if (popupRef.current && popupInfo) {
      const popup = popupRef.current

      const map = mapRef.current.getMap()
      const mapBounds = map.getContainer().getBoundingClientRect()

      let { x, y } = popupPosition
      const padding = 10

      // Always position to the left of the marker
      x = x - popup.offsetWidth - padding

      if (y + popup.offsetHeight > mapBounds.height - padding) {
        y = mapBounds.height - popup.offsetHeight - padding
      }

      popup.style.transform = `translate(${x}px, ${y}px)`
    }
  }, [popupInfo, popupPosition])

  return (
    <div className="w-full h-full flex flex-col relative">
      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        onMoveEnd={handleMapMove}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={mapRef}
      >
        <NavigationControl position="top-right" />
        {activities
          ?.filter(
            (activity) => !hoveredActivity || activity.id !== hoveredActivity.id
          )
          .map((activity) => renderMarker(activity, false))}
        {hoveredActivity && renderMarker(hoveredActivity, true)}
      </Map>
      {popupInfo && (
        <div
          ref={popupRef}
          className="absolute z-50 bg-white rounded-lg shadow-lg max-w-[300px] max-h-[80vh] overflow-y-auto"
          style={{
            left: 0,
            top: 0,
            transform: "translate(0, 0)",
          }}
        >
          <div className="p-2 w-full">
            <div className="flex justify-between items-start mb-2">
              <h3 className="font-bold text-lg truncate">{popupInfo.name}</h3>
              <div
                className={`p-1 rounded-xl w-16 h-8 flex items-center justify-center text-white text-lg font-bold ${getRatingBackgroundColor(
                  popupInfo.rating
                )}`}
              >
                <span className="mr-1">
                  {categories.find(
                    (cat) =>
                      cat.name.toLowerCase() ===
                      (popupInfo.category || "").toLowerCase()
                  )?.emoji || "❓"}
                </span>
                {popupInfo.rating ? popupInfo.rating.toFixed(1) : "0.0"}
              </div>
            </div>
            <div className="w-full h-48 mb-2 overflow-hidden rounded">
              <img
                className="w-full h-full object-cover"
                src={popupInfo.photo_links?.[0] || FALLBACK_IMAGE}
                alt={popupInfo.name}
              />
            </div>
            <p className="text-gray-600 text-sm">{popupInfo.category}</p>
            <p className="text-gray-600 text-sm">{popupInfo.address}</p>
            <p className="text-gray-600 text-sm mb-1">
              {popupInfo.international_phone_number}
            </p>
            {popupInfo.website && (
              <p className="text-sm">
                <a
                  href={popupInfo.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  Website
                </a>
              </p>
            )}
            {/* Add pros and cons */}
            <div className="mt-2">
              <h4 className="font-semibold text-sm mb-1">👍 Pros:</h4>
              <ul className="list-disc list-inside text-xs">
                {popupInfo.pros_cons?.pros?.map((pro, index) => (
                  <li key={index} className="text-black">
                    {pro}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-2">
              <h4 className="font-semibold text-sm mb-1">👎 Cons:</h4>
              <ul className="list-disc list-inside text-xs">
                {popupInfo.pros_cons?.cons?.map((con, index) => (
                  <li key={index} className="text-black">
                    {con}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
