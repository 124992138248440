import React from "react"
import categories from "../../assets/categories.js"

export default function FilterBar({ filters, onFilterChange, cities }) {
  const handleCityChange = (e) => {
    onFilterChange({ ...filters, city: e.target.value })
  }

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value
    onFilterChange({ ...filters, category: selectedCategory })
  }

  const handleAgeChange = (e) => {
    onFilterChange({ ...filters, age: e.target.value })
  }

  const ageOptions = Array.from({ length: 19 }, (_, i) => i)

  return (
    <div className="py-4 w-full h-16 flex justify-between items-center text-black border-b border-neutral-200 bg-white sticky top-0 left-0 px-3 md:px-6">
      <div className="flex items-center space-x-4">
        {cities.length > 1 ? (
          <>
            <label htmlFor="city-filter" className="font-semibold">
              City:
            </label>
            <select
              id="city-filter"
              value={filters.city}
              onChange={handleCityChange}
              className="border border-gray-300 rounded px-2 py-1"
            >
              <option value="">Select a city</option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </>
        ) : (
          <span className="font-semibold">City: {filters.city}</span>
        )}

        <label htmlFor="category-filter" className="font-semibold">
          Category:
        </label>
        <select
          id="category-filter"
          value={filters.category}
          onChange={handleCategoryChange}
          className="border border-gray-300 rounded px-2 py-1"
        >
          <option value="">Everything</option>
          {categories.map(({ name, emoji }) => (
            <option key={name} value={name}>
              {emoji} {name}
            </option>
          ))}
        </select>

        <label htmlFor="age-filter" className="font-semibold">
          Age:
        </label>
        <select
          id="age-filter"
          value={filters.age}
          onChange={handleAgeChange}
          className="border border-gray-300 rounded px-2 py-1"
        >
          <option value="">All ages</option>
          {ageOptions.map((age) => (
            <option key={age} value={age}>
              {age}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
